import "../App.css";
import UniversityHeading from './UniversityHeading';
import Courses from './Courses';
import class12 from '../IMG/class12.webp';
import class11 from '../IMG/class11.webp';
import Testimonials from './Testimonials';
import Helmet from "react-helmet";


export default function Home({ setProgress }) {

  return (
    <>
      <Helmet>
        <title>Learn to Define - TGU</title>
        <meta name="description" content="TGU also known as TGUniversity is Your Virtual University Where We Will Prepare You For Your Board Examinations. I Created Free Courses Based On NCERT with Free Notes for Class11 and Class12. Access The Course, Download the Notes & Save Your Time." />
        <meta name="keywords" content="TGU, Class11, Class12, Handwritten pdf notes, Physics, Chemistry, Courses, Free Notes, TGUniversity, Learn to Define, Your Virtual University" />
        <meta name="author" content="Tarun Gupta" />
      </Helmet>
      <UniversityHeading />
      <Courses headingClass="Class-12" Course="/Class12" img={class12} />
      <Courses headingClass="Class-11" Course="/Class11" img={class11} />
      <Testimonials />
    </>
  )
}
