import basicOrganic from '../IMG/Basic Organic Chemistry.webp';
import oneShotNameRen from '../IMG/All Name Reaction.webp';
import haloAlkanesHaloArenes from '../IMG/Haloalkane & Haloarene.webp';
import electriChargesFields from '../IMG/Electric Charges & Fields.webp';
import alcoholsPhenolsEthers from '../IMG/ALCOHOLS PHENOLS & ETHERS.webp';

const FeaturedCourseData = [
    {
        img: basicOrganic,
        title: "Basic concepts of Organic Chemistry",
        overview: "This lecture is a base of Organic Chemistry. It consist IUPAC Rules, Important Compounds, Priority Series and Self-Assessment which will Help you to test your understanding of concepts. Also you can say it is Class11 Flashback lecture.",
        noteslink: "https://drive.google.com/uc?export=download&id=1dT-oQdqo7Dm9ooHr9yotnxrdnT44gzJX",
        ytlink: "https://youtu.be/JHahYawMbw0"

    },

    {
        img: oneShotNameRen, title: "All Name Reactions - One Shot",
        overview: "All 30 Name Reactions are covered in this One Shot with best approach of learning. Every year minimum 2 Name Reactions asked in the board examinations. Make sure you download the notes & Save Your Time.",
        noteslink: "https://drive.google.com/uc?export=download&id=1ZnjbBYx-qKt5-62JTKuigyzXxeKhR1US",
        ytlink: "https://youtu.be/Cl8tjTIRiI0?si=r0H74bRDc7-NT9wa"

    },

    {
        img: haloAlkanesHaloArenes,
        title: "Haloalkanes & Haloarenes [Playlist]",
        overview: "This Playlist of Haloalkanes & Haloarenes will cover NCERT line by line and this chapter will give you the experience to learn further chapters of Organic Chemistry. Haloalkanes & Haloarenes contains 6 marks in your board examination.",
        noteslink: "https://drive.google.com/uc?export=download&id=1sJM9YBdRJH-C-u7Mgb91mFSwpQHM_74N",
        ytlink: "https://youtube.com/playlist?list=PLsXhZoGUNc_bZAXtELY5JTqSI2fqgsqEk&si=-Vflaw5mxliwdjY2"
    },

    {
        img: electriChargesFields,
        title: "Electric Charges & Fields [Playlist]",
        overview: "This is the 1st chapter of the Electrostatics Unit-1. Learn Electric Charges & Fields with this Playlist and maximise your score. Also don't forget to download handwritten pdf notes which covers all the topics of NCERT in simple language.",
        noteslink: "https://drive.google.com/uc?export=download&id=19nIbXeZpynolVl77LeuD-j9IsNgnlT4b",
        ytlink: "https://youtube.com/playlist?list=PLsXhZoGUNc_bD-8iS0XWKoUT4xrzP3OF9&si=IhyeCU_215TNeNu1"
    },

    {
        img: alcoholsPhenolsEthers,
        title: "Alcohols Phenols & Ethers [Playlist]",
        overview: "Alcohols Phenols & Ethers Contains 6 Marks in Your Board Examination So make sure you learn all the concepts of this chapter to get full marks in your exam.",
        noteslink: "https://drive.google.com/uc?export=download&id=1-Cdx4aWch3-ulBmT8vbTcJPBk2Gt-7tJ",
        ytlink: "https://youtube.com/playlist?list=PLsXhZoGUNc_YcNOqdmQQkt_ndo8ZfEeEc&si=9NgHZ2nLylSCDN56"
    },
]

export default FeaturedCourseData;