import React from 'react';
import { ImQuotesLeft } from "react-icons/im";

export default function Testimonials() {
    return (
        <>
            <section className="testimonials">
                <div className="container-fluid">
                    <div className="row">
                        <div className="mt-5 mb-3 text-center">
                            <h2 className="fw-bold fs-2 text-danger">
                                Testimonials
                            </h2>
                        </div>
                    </div>
                    <div className="row mt-lg-1 mb-lg-4 m-lg-5">
                        <div className="mb-2 col-lg-4">
                            <div className="p-3 bg-light testi_shadow">
                                <div className="align_text">
                                    <p className="text-muted fw-light">
                                        <ImQuotesLeft /><br />
                                        Hello Sir, your creative methods to learn concepts were very awesome. Also your weekly
                                        test plans helped me alot to remember all the concepts throughout the year. <br />
                                        Hats off to you.
                                    </p>
                                    <p className="mb-0"><strong>Akash Dhiman</strong></p>
                                    <p className="text-muted mb-0">Topper, 2018</p>
                                </div>
                            </div>
                        </div>
                        <div className="mb-2 col-lg-4">
                            <div className="p-3 bg-light testi_shadow">
                                <div className="align_text">
                                    <p className="text-muted fw-light">
                                    <ImQuotesLeft /><br />
                                        Thank you Bhaiya!!! I want to say that your revision method is really very great
                                        it helps us to revise any chapters in just minutes & the way you also encourage to
                                        find
                                        science in daily
                                        life that was real learning part for me.
                                    </p>
                                    <p className="mb-0"><strong>Vinay Gupta</strong></p>
                                    <p className="text-muted mb-0">Topper, 2019</p>
                                </div>
                            </div>
                        </div>
                        <div className="mb-2 col-lg-4">
                            <div className="p-3 bg-light testi_shadow">
                                <div className="align_text">
                                    <p className="text-muted fw-light">
                                    <ImQuotesLeft /><br />
                                        Hello Bhaiya, Thank you very much for your Lectures. It helped me a lot during my
                                        preparations & I was able to score 95+ marks in my exam. <br />
                                        Thank you again!!
                                    </p>
                                    <p className="mb-0"><strong>Nishu Vishwakarma</strong></p>
                                    <p className="text-muted mb-0">Topper, 2020</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

        </>
    )
}
