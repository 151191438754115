import React from 'react';
import NotesGrid from './NotesGrid';
import NotesData from './NotesData';
import NotesData11 from './NotesData11';
import NotesGrid11 from './NotesGrid11';
import Helmet from 'react-helmet'

export default function Notes({ setProgress }) {

    return (
        <>

            <Helmet>
                <title>Download Notes - Class 11 & Class 12 | TGU</title>
                <meta name="description" content="Download handwritten pdf notes of physics and chemistry of class11 and class12. These Notes covers ncert line by line in simple language with multiple Self-Assessments" />
                <meta name="keywords" content="TGU, Class11, Class12, Handwritten pdf notes, Physics Notes, Chemistry Notes, Haloalkanes & Haloarenes, Alcohols Phenols & Ethers, Aldehydes Ketones and Carboxylic Acids, Amines, Biomolecules, Solutions, Electrochemistry, Chemical Kinetics, The d and f Block Elements, Coordination Compounds, Electric Charges & Fields, Electromagnetic Waves, Ray Optics, Atoms, Nuclei, Semiconductors, Units and Measurements, Some Basic Concepts of Chemistry" />
                <meta name="author" content="Tarun Gupta" />
            </Helmet>

            <section className="Notes Heading">
                <div className="container">
                    <div className="row">
                        <div className="mt-5 pt-5 text-center">
                            <h3 className="fs-2 fw-bold">Class 12</h3>
                        </div>
                    </div>
                </div>
            </section>

            <section className="chemistry_notes">
                <div className="container-fluid">
                    <div className="row">

                        {
                            NotesData.map((value, index) => {
                                return (
                                    <NotesGrid key={index}
                                        imgsrc={value.img}
                                        noteslink={value.link}
                                    />
                                )

                            })
                        }

                    </div>
                </div>
            </section>

            {/* Class 11 Notes Started */}
            <section className="Notes Heading">
                <div className="container">
                    <div className="row">
                        <div className="mt-3 text-center">
                            <h3 className="fs-2 fw-bold">Class 11</h3>
                        </div>
                    </div>
                </div>
            </section>

            <section className="chemistry_notes">
                <div className="container-fluid">
                    <div className="row justify-content-center px-1">

                        {
                            NotesData11.map((value, index) => {
                                return (
                                    <NotesGrid11 key={index}
                                        title={value.chapterName}
                                        noteslink={value.link}
                                    />
                                )

                            })
                        }

                    </div>
                </div>
            </section>



        </>
    )
}
