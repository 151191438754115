import React from 'react';
import CourseGrid from './CourseGrid';
import EliteCourse from './EliteCourseData12';
import FeaturedCourse from './FeaturedCourseData12';
import { Helmet } from 'react-helmet';

export default function EliteClass12({ setProgress }) {

    return (
        <>

            <Helmet>
                <title>NCERT Class 12 | TGU</title>
                <meta name="description" content="Download handwritten pdf notes of physics and chemistry of class12. These Notes covers ncert line by line in simple language with multiple Self-Assessments" />
                <meta name="keywords" content="TGU, NCERT, Class12, Handwritten pdf notes, Physics Notes, Chemistry Notes, 30Days 30Name Reactions, Basic concepts of Organic Chemistry, All Name Reactions, Haloalkanes & Haloarenes, Electric Charges & Fields, Alcohols Phenols & Ethers" />
                <meta name="author" content="Tarun Gupta" />
            </Helmet>

            <section className="elite_courses">
                <div className="container">
                    <div className="row">
                        <div className="mt-5 pt-5 text-center">
                            <h2 className="fw-semibold fs-2 text-danger m-0">Elite Courses</h2>
                            <p className="text-secondary m-0">Design & Created By TGU</p>
                        </div>
                    </div>
                    <div className="row mx-lg-0">
                        {
                            EliteCourse.map((value, index) => {
                                return (
                                    <CourseGrid key={index}
                                        imgsrc={value.img}
                                        title={value.title}
                                        para={value.overview}
                                        downloadlink={value.noteslink}
                                        youtubelink={value.ytlink}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </section>

            <section className="featured_courses">
                <div className="container">
                    <div className="row">
                        <div className="mt-4 text-center">
                            <h2 className="fw-semibold fs-2 m-0 text-danger">Featured Courses</h2>
                        </div>
                    </div>
                    <div className="row mx-lg-0">
                        {
                            FeaturedCourse.map((value, index) => {
                                return (
                                    <CourseGrid key={index}
                                        imgsrc={value.img}
                                        title={value.title}
                                        para={value.overview}
                                        downloadlink={value.noteslink}
                                        youtubelink={value.ytlink}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </section>

        </>
    )
}
