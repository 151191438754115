import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { Helmet } from 'react-helmet';

export default function Contact({ setProgress }) {


  return (
    <>

      <Helmet>
        <title>Contact - TGU | TGUniversity</title>
        <meta name="description" content="TGPublication is publishing budget friendly books based on NCERT of physics and chemistry for class11 and class12" />
        <meta name="keywords" content="TGU, Contact, TGUniversity, TGU facebook, TGU Instagram, TGU Twitter, TGU Linkdin, TGU Youtube, TGU Contact, Discipline, Motivation, Queries, Suggestion, Feedbacks, Stay Connected" />
        <meta name="author" content="Tarun Gupta" />
      </Helmet>

      <section className="contact bottomMargin">
        <div className="container">
          <div className="row mx-2 ">
            <div className="border_contact bg-light " id="topMargin">
              <h2 className="text-center mt-4 fs-1 fw-bold text-decoration text-danger">Ask me!</h2>
              <p className="fs-6 text-center text-muted m-0">For Discipline & Motivation</p>
              <div className="social_media_contact text-center fs-1">
                <Link to="https://www.facebook.com/tgtvTGU" className="me-4">
                  <FaFacebookSquare />
                </Link>
                <Link to="https://www.instagram.com/tg__tv_/" className="me-4">
                  <FaInstagramSquare />
                </Link>
                <Link to="https://twitter.com/tg__tv_" className="me-4">
                  <FaTwitterSquare />
                </Link>
                <Link to="https://www.linkedin.com/in/tg--tv-/" className="">
                  <FaLinkedin />
                </Link>
              </div>
              <p className="fs-6 text-center text-muted mt-2 m-0">For Queries/ Suggestions/ Feedbacks</p>
              <div className="social_media_contact text-center fs-1 mb-4">
                <Link to="mailto:contacttg@tgu.net.in" className="">
                  <FaEnvelope />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
