import React from 'react';
import { Link } from 'react-router-dom';
// import FeaturedCourse from './FeaturedCourse12';
// import CourseData from './CourseData';

export default function CourseGrid(props) {
    return (
        <>
            <div className="col-lg-4 course mt-3">
                <div className="course_shadow me-lg-2 border-0 rounded-top course_height">
                    <img src={props.imgsrc} alt="Courses" className="border rounded-top" width="100%" />
                    <p className="text-secondary pt-2 px-4 course_free">Paid Course For Free</p>
                    <h2 className="fs-5 fw-semibold px-3 m-0">{props.title}</h2>
                    <p className="text-secondary pt-2 px-3 overview">{props.para}</p>
                    <div className="px-4 pb-3">
                        <Link to={props.youtubelink}><button type="button" className="btn btn-danger rounded-pill btn-sm">Watch Now</button></Link>
                        <Link to={props.downloadlink}><button type="button"
                            className="btn btn-danger rounded-pill btn-sm float-end">Download Notes</button></Link>
                    </div>
                </div>
            </div>
        </>
    )
}
