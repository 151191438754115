import phyInMin from '../IMG/phy in min.png';
import cheInMin from '../IMG/che in min.webp';


const EliteCourseData = [
    {
        img: phyInMin,
        title: "Physics in Y Minutes - Class11",
        overview: "These Lectures are for Class-11 will be the 1st ever Lectures in the History of Youtube/Online Mode in which we will cover NCERT in Minutes. Just Listen, Recall and Revise the Chapters. I have Converted the Short Notes into the lecture format. Be a part Of History and Comment \"#creatinghistoryonyoutube\".",
        noteslink: "/Notes",
        ytlink: "https://www.youtube.com/playlist?list=PLsXhZoGUNc_Y0s7BhgsLSLHAKopQZzEQc"
    },
    {
        img: cheInMin,
        title: "Chemistry in X Minutes - Class11",
        overview: "These Lectures will be the 1st ever Lectures in the ' History of YouTube/Online Mode ' in which we will cover NCERT in Minutes to Revise the Chapters. I have Converted the Short Notes into the lecture format. That is why it is called \"QUICK REVISION\".",
        noteslink: "/Notes",
        ytlink: "https://www.youtube.com/playlist?list=PLsXhZoGUNc_YNkLyKz6_7DZlQZ2Z0FamD"
    },

]
export default EliteCourseData;
