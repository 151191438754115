import './App.css';
import { useState } from 'react';
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import Class11 from './Components/Class11';
import Class12 from './Components/Class12';
import Notes from './Components/Notes';
import Books from './Components/Books';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import Error from './Components/Error';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';

// ReactGA.initialize('');
ReactGA.initialize('G-MLZ8HPM677');


function App() {

  const [progress, setProgress] = useState(0)

  return (
    <>
    <Helmet>
    <meta name="description" content="TGU also known as TGUniversity is Your Virtual University Where We Will Prepare You For Your Board Examinations. I Created Free Courses Based On NCERT with Free Notes for Class11 and Class12. Access The Course, Download the Notes & Save Your Time." />
    <meta name="keywords" content="TGU, Class11, Class12, Handwritten pdf notes, Physics, Chemistry, TGUniversity, Learn to Define, Your Virtual University" />
    <meta name="author" content="Tarun Gupta" />
    <link rel="canonical" href="https://www.tgu.net.in/" />
    </Helmet>


      <LoadingBar color='red' height={2} waitingTime={400} progress={progress} onLoaderFinished={() => setProgress(0)} />

      <BrowserRouter>

        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home setProgress = {setProgress} />} />
          {/* <Route exact path="/Home" element={<Home />} /> */}
          <Route exact path="/Class11" element={<Class11 setProgress = {setProgress} />} />
          <Route exact path="/Class12" element={<Class12 setProgress = {setProgress} />} />
          <Route exact path="/Notes" element={<Notes setProgress = {setProgress} />} />
          <Route exact path="/Books" element={<Books setProgress = {setProgress} />} />
          <Route exact path="/Contact" element={<Contact setProgress = {setProgress} />} />
          <Route path="*" element={<Error />} />
        </Routes>
        <Footer />

      </BrowserRouter>
    </>
  );
}

export default App;
