import React from 'react'
import { Link } from 'react-router-dom'


export default function NotesGrid11(props) {
  return (
    <>
                <div className="col-lg-5 mt-2 py-2 m-1 border">
                    <div className="d-flex justify-content-between">
                        <p className="my-auto class11Size">{props.title}</p>
                        <Link to={props.noteslink}><button type="button" className="btn btn-danger rounded-pill btn-sm fw-bold px-2">PDF Notes</button></Link>
                    </div>

                </div>

    </>
  )
}
