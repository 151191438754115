import React, { useEffect, useState } from 'react';
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.min.js";
import { Link } from 'react-router-dom';



export default function Navbar() {

    

    // useEffect(()=>{
    //     setProgress(50)
    //     setProgress(100)
    // },[])

    return (
        <>
        

            <header>
                <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top shadow">
                    <div className="container-fluid px-4">
                        <h1><Link className="navbar fs-2 word_logo" to="/">TGU</Link></h1>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                            <ul className="navbar-nav">
                                <li className="nav-item px-1">
                                    <Link className="nav-link" aria-current="page" to="/">Home</Link>
                                </li>
                                <li className="nav-item px-1">
                                    <Link className="nav-link" aria-current="page" to="/Class11">Class11</Link>
                                </li>
                                <li className="nav-item px-1">
                                    <Link className="nav-link" aria-current="page" to="/Class12">Class12</Link>
                                </li>

                                {/* <li className="nav-item px-1">
                            <Link className="nav-link " to="/tutorials">Tutorial</Link>
                        </li> */}

                                <li className="nav-item px-1">
                                    <Link className="nav-link " to="/Notes">Notes</Link>
                                </li>
                                <li className="nav-item px-1">
                                    <Link className="nav-link " to="/Books">Books</Link>
                                </li>
                                <li className="nav-item px-1">
                                    <Link className="nav-link " to="/Contact">Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

        </>
    )
}
