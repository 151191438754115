// import c1 from '../IMG/c1.webp';
// import c2 from '../IMG/c2.webp';
// import c3 from '../IMG/c3.webp';
// import c4 from '../IMG/c4.webp';
// import c5 from '../IMG/c5.webp';
import c6 from '../IMG/c6.webp';
import c7 from '../IMG/c7.webp';
import c8 from '../IMG/c8.webp';
import c9 from '../IMG/c9.webp';
import c10 from '../IMG/c10.webp';
import u1c1 from '../IMG/u1c1.webp';
// import u1c2 from '../IMG/u1c2.webp';
// import u2c3 from '../IMG/u2c3.webp';
// import u3c4 from '../IMG/u3c4.webp';
// import u3c5 from '../IMG/u3c5.webp';
// import u4c6 from '../IMG/u4c6.webp';
// import u4c7 from '../IMG/u4c7.webp';
import u5c8 from '../IMG/u5c8.webp';
import u6c9 from '../IMG/u6c9.webp';
// import u6c10 from '../IMG/u6c10.webp';
// import u7c11 from '../IMG/u7c11.webp';
import u8c12 from '../IMG/u8c12.webp';
import u8c13 from '../IMG/u8c13.webp';
import u9c14 from '../IMG/u9c14.webp';

const NotesData = [

    {
        img: c6,
        link: "https://drive.google.com/uc?export=download&id=1sJM9YBdRJH-C-u7Mgb91mFSwpQHM_74N"
    },
    { img: c7, link: "https://drive.google.com/uc?export=download&id=1-Cdx4aWch3-ulBmT8vbTcJPBk2Gt-7tJ" },
    { img: c8, link: "https://drive.google.com/uc?export=download&id=11AMzisUJFjS_zlrLiOr1vLVEa6Kl5785" },
    { img: c9, link: "https://drive.google.com/uc?export=download&id=1ZeR7MN7xQbg7z68imnaGzRHszXmLPrYP" },
    { img: c10, link: "https://drive.google.com/uc?export=download&id=1x9ndUxZ0o_u_8jBnbZqF9wCcszE4aHXv" },
    { img: u1c1, link: "https://drive.google.com/uc?export=download&id=19nIbXeZpynolVl77LeuD-j9IsNgnlT4b" },
    { img: u5c8, link: "https://drive.google.com/uc?export=download&id=12RFnxUrZd0F6HsznZk2E3cCjl0imOi2w" },
    { img: u6c9, link: "https://drive.google.com/uc?export=download&id=1JSVn3SzLs6HZS6RK7TiBqAf3DgO7gBww" },
    { img: u8c12, link: "https://drive.google.com/uc?export=download&id=1Blw6KJo5zN3t4NxkLsF02Fi72wNWtJxc" },
    { img: u8c13, link: "https://drive.google.com/uc?export=download&id=1b4hRA8MLgRr4RG0ZPhUoPxR7Jk7VWg_i" },
    { img: u9c14, link: "https://drive.google.com/uc?export=download&id=1eiIwNTzKWWsrgRS5oiG3JgjLi3hbhHzc" },

]

export default NotesData;