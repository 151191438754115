const NotesData11 = [
    {
        chapterName: "Units and Measurements",
        link: "https://drive.google.com/uc?export=download&id=1GYQc9Is3XNswG824tKAIm2gM-8DZnU_S"
    },
    {
        chapterName: "Some Basic Concepts of Chemistry",
        link: "https://drive.google.com/uc?export=download&id=1Zd6jQ-igMQufD7Gv2DpNZMKqPUaPIA5R"

    },
    // {
    //     chapterName: "Motion in a Straight Line",
    //     link: "https://drive.google.com/uc?export=download&id=1-Cdx4aWch3-ulBmT8vbTcJPBk2Gt-7tJ"
    // },
    // {
    //     chapterName: "Motion in a Plane",
    //     link: "https://drive.google.com/uc?export=download&id=1-Cdx4aWch3-ulBmT8vbTcJPBk2Gt-7tJ"
    // }
    
]

export default NotesData11;