import React from 'react';
import {Link} from 'react-router-dom';

export default function Courses(props) {
  return (
    <>
      <div className="container-fluid">
        <div className="row class_12 py-4">
          <div className="col-lg-6 pt-4 pb-4 text-center">
            <h2 className="headingforclass">{props.headingClass}</h2>
            <p className="fs-6">Complete NCERT with Notes!!!</p>
            <Link to={props.Course} className="no_underline"><button
              className="btn btn-danger mt-3 d-block d-lg-inline me-lg-2 mx-auto">Courses</button></Link>
            {/* <Link to="/Tutorials" className="no_underline"><button className="btn btn-danger mt-3 d-block d-lg-inline me-lg-2 mx-auto">Tutorial</button></Link> */}
            <Link to="/Notes" className="no_underline"><button className="btn btn-danger mt-3 d-block d-lg-inline mx-auto">Download Notes</button></Link>
          </div>
          <div className="col-lg-6 text-center d-flex align-items-center justify-content-center">
            <img src={props.img} alt="class Img" height="230px"
              className="d-none d-lg-inline cursor class_shadow" />
          </div>
        </div>
        </div>
      </>
      )
}
