import React from 'react';
import TGUBanner from '../IMG/TGUBanner.png';
import { Link } from 'react-router-dom';
import { Typewriter } from 'react-simple-typewriter';


export default function UniversityHeading() {


    return (
        <>
            <section className="content" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mt-5 pt-5">
                            <h2 className="heading_center mb-0">Your Virtual <span className="">University</span></h2>
                            <h3 className="heading_center fs-4"><span id="element">
                                <Typewriter
                                    words={["Welcome to TGUniversity", "Learn to Define", "Class Ⅻ", "Learn NCERT", "Class Ⅺ", "Learn Physics", "Solve NCERT Solutions", "Learn Chemistry"]}
                                    loop={Infinity}
                                    cursor={true}
                                    cursorStyle='✍️'
                                    typeSpeed={80}
                                    deleteSpeed={50}
                                    delaySpeed={1250}
                                />
                            </span></h3>
                            <p className="text-secondary university_overview">TGU is Your Virtual University Where We Will
                                Prepare You For Your
                                Board Examinations. <br />I
                                Created
                                Free Courses Based On NCERT with Free Notes. Access The Course,
                                Download the Notes &
                                Save
                                Your
                                Time.<br />Welcome To The TGFamily❤.</p>
                            <Link to="/books"><button type="button" className="btn btn-outline-danger">Explore
                                Books</button></Link>
                        </div>
                        <div className="col-lg-6">
                            <img src={TGUBanner} alt="Science Img" width="100%" />
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}
