import React from 'react';
import upcomingBookImg from '../IMG/tutorial.webp';
import { Helmet } from 'react-helmet';

export default function Books({ setProgress }) {

    return (
        <>

            <Helmet>
                <title>Books - TGPublication | TGU</title>
                <meta name="description" content="TGPublication is publishing budget friendly books based on NCERT of physics and chemistry for class11 and class12" />
                <meta name="keywords" content="TGU, TGPublication, TGP, Class11 Books, Class 12 Books, Chemistry Best Books, Physics Best Books, Class11 best books, class12 best books" />
                <meta name="author" content="Tarun Gupta" />
            </Helmet>

            <section className="tutorial">
                <div className="container">
                    <div className="row">
                        <div className="col-12 mt-5 pt-5 text-center">
                            <h2>TGPublication <span className="fs-4 word_logo">Coming soon!!!</span></h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center text-muted">
                            <p>We are publishing budget friendly books based on NCERT.</p>
                        </div>
                    </div>
                    <div className="row bottomMargin">
                        <div className="col-12 d-flex justify-content-center mb-5 ">
                            <img src={upcomingBookImg} alt="Books" className="img-fluid book_responsive" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
