import React from 'react';
import { Link } from 'react-router-dom';

export default function NotesGrid(props) {
    return (
        <>
            <div className="col-lg-3 d-flex">
                <div className="m-3 mt-0 course_shadow pt-2 p-4 pb-3">
                    <div className="col-12">
                        <img src={props.imgsrc} alt="Notes" width="100%" />
                    </div>
                    <div className="text-center">
                        <p className="text-secondary">Download Notes Here!!</p>
                        <Link to={props.noteslink}><button type="button" className="btn btn-danger rounded-pill btn-sm fw-bold w-50">Download PDF</button></Link>
                    </div>
                </div>
            </div>
        </>
    )
}
