import React from 'react';
import { Link } from 'react-router-dom';

export default function Error() {
  return (
    <>
      <section className="error bottomMargin">
        <div className="container">
          <div className="row mx-2 ">
            <div className="border border-dark bg-light " id="topMargin">
              <h2 className="text-center mt-4 fs-1 fw-bold text-decoration text-dark">Not Found!!!</h2>
              <p className="fs-6 text-center text-muted mt-2 m-0">You have entered wrong Website address, please enter correctly.</p>
              <p className="fs-6 text-center text-dark m-0 my-3">Or find tgu courses for <Link to="/Class11"> <span>class11</span> </Link > and <Link to="/Class12"> <span>class12</span> </Link ></p>
              <p className="fs-6 text-center text-dark m-0 my-3"> Or download pdf <Link to="/Notes"> <span>notes</span> </Link></p>
              <div className="text-center">
                <Link to="/"><button type="button" className="fs-5 btn btn-light my-3 text-danger">Homepage</button></Link>
              </div>
              {/* <div className="social_media_contact text-center fs-1">
                <Link to="https://www.facebook.com/tgtvTGU" className="me-4">
                  <FaFacebookSquare />
                </Link>
                <Link to="https://www.instagram.com/tg__tv_/" className="me-4">
                  <FaInstagramSquare />
                </Link>
                <Link to="https://twitter.com/tg__tv_" className="me-4">
                  <FaTwitterSquare />
                </Link>
                <Link to="https://www.linkedin.com/in/tg--tv-/" className="">
                  <FaLinkedin />
                </Link>
              </div>
              <p className="fs-6 text-center text-muted mt-2 m-0">For Queries/ Suggestions/ Feedbacks</p>
              <div className="social_media_contact text-center fs-1 mb-4">
                <Link to="#" className="">
                  <FaEnvelope />
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
