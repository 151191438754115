import React from 'react';
import TGUlogo from '../IMG/TGUlogo.png';
import {Link} from 'react-router-dom';
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";


export default function Footer() {
  return (
    <>
      <footer className="container-fluid mt-5 mb-3">
        <div className="row text-center">
          <div className="col-lg-4 text-lg-start px-4">
            <Link to="/"> <img src={TGUlogo} alt="Logo" width="50px" className="cursor" /></Link>
          </div>
          <div className="col-lg-4 d-flex justify-content-center align-items-center">
            <p className="text-muted my-auto">Copyright &copy; 2023 | tgu.net.in</p>
          </div>
          <div className="col-lg-4 social_media d-lg-flex justify-content-end align-items-center px-4 py-2">
            <Link to="https://www.facebook.com/tgtvTGU" className="me-3">
              <FaFacebookF />
            </Link>
            <Link to="https://www.instagram.com/tg__tv_/" className="me-3">
              <FaInstagram />
            </Link>
            <Link to="https://twitter.com/tg__tv_" className="me-3">
            <FaTwitter />
            </Link>
            <Link to="https://www.linkedin.com/in/tg--tv-/" className="me-3">
              <FaLinkedinIn />
            </Link>
            <Link to="https://www.youtube.com/@TGUniversity" className="">
              <FaYoutube />
            </Link>
          </div>
        </div>
      </footer>
    </>
  )
}
