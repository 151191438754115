import dayNameReation from '../IMG/30daysnamereaction.webp';
import phyInMin from '../IMG/phy in min.png';
import cheInMin from '../IMG/che in min.webp';


const EliteCourseData = [

    {
        img: dayNameReation,
        title: "30Days = 30Name Reactions - Class12",
        overview: "30Days 30Name Reactions means You will learn all the Name Reactions of Organic Chemistry in just 30days without any effort with this creative series. Follow this series and you will find that this is the best approach to learn all the name reactions in just 30 days without any tips and tricks.",
        noteslink: "https://drive.google.com/uc?export=download&id=1ZnjbBYx-qKt5-62JTKuigyzXxeKhR1US",
        ytlink: "https://www.youtube.com/playlist?list=PLsXhZoGUNc_ZFYpuTX4M_BJLo0Rthko0K"

    },

    {
        img: phyInMin,
        title: "Physics in Y Minutes - Class12",
        overview: "These Lectures will be the 1st ever lectures in the History of Youtube/Online Mode in which we will cover NCERT in Minutes to Revise the Chapters. I have Converted the Short Notes into lecture format. So Be a part of History and Comment \"#creatinghistoryonyoutube\".",
        noteslink: "/Notes",
        ytlink: "https://www.youtube.com/playlist?list=PLsXhZoGUNc_ZbpVEwzshKLXRVqPB8kExU"
    },

    {
        img: cheInMin,
        title: "Chemistry in X Minutes - Class12",
        overview: "These Lectures are also the 1st ever lectures in the 'History of YouTube/Online Mode' in which we will cover NCERT in Minutes to Revise the Chapters. I have Converted the Short Notes into lecture format. That is why it is called \"QUICK REVISION\".",
        noteslink: "/Notes",
        ytlink: "https://www.youtube.com/playlist?list=PLsXhZoGUNc_ZMVl3ajNXwrBPndwzH6xQD"
    },

]
export default EliteCourseData;